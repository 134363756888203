import type { IconProps } from '../../../../../src/features/common/types/common';

const CrossIcon = ({ width }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M3.24677 1.22593L3.24678 1.22593L3.24545 1.22462C3.11271 1.09495 2.95467 0.994015 2.7812 0.928103C2.60774 0.862191 2.42255 0.832718 2.23718 0.841521L2.23718 0.841498L2.23444 0.841675C1.87249 0.865096 1.53144 1.01944 1.27496 1.27589C1.01849 1.53234 0.864122 1.87337 0.840698 2.2353L0.840572 2.23529L0.840413 2.24165C0.835951 2.42076 0.867642 2.59894 0.933591 2.76553C0.99954 2.93212 1.09839 3.08371 1.22425 3.21124L1.225 3.212L7.01391 9.00035L1.24356 14.7701C1.11446 14.8958 1.01217 15.0463 0.942868 15.2126C0.873415 15.3793 0.838544 15.5584 0.840372 15.7389L0.840232 15.7389L0.84058 15.7456C0.859772 16.1143 1.01487 16.4628 1.27594 16.7238C1.53701 16.9849 1.88554 17.1399 2.25423 17.1592L2.25726 17.1593C2.44408 17.1654 2.63015 17.1328 2.8037 17.0634C2.97702 16.9941 3.13409 16.8896 3.26501 16.7565C3.26518 16.7563 3.26536 16.7562 3.26553 16.756L9.00032 11.0218L14.7525 16.756C14.7527 16.7562 14.7529 16.7564 14.7531 16.7566C14.884 16.8896 15.0411 16.9941 15.2144 17.0634C15.3879 17.1328 15.574 17.1654 15.7608 17.1593L15.7608 17.1593L15.7659 17.159C16.1278 17.1356 16.4689 16.9813 16.7253 16.7248C16.9818 16.4683 17.1362 16.1273 17.1596 15.7654L17.1598 15.7654L17.1599 15.7568C17.164 15.3936 17.0189 15.0425 16.7568 14.788L10.986 9.00035L16.7568 3.21269C16.8859 3.08704 16.9881 2.93654 17.0574 2.77028C17.1269 2.6036 17.1617 2.42456 17.1599 2.24401L17.1602 2.24401L17.1596 2.2353C17.1362 1.87337 16.9818 1.53234 16.7253 1.27589C16.4689 1.01944 16.1278 0.865096 15.7659 0.841675L15.7659 0.841596L15.7608 0.841429C15.574 0.835251 15.3879 0.8679 15.2144 0.937314C15.0411 1.00662 14.884 1.11108 14.7531 1.24411C14.7529 1.2443 14.7527 1.2445 14.7525 1.2447L9.00032 6.97892L3.24677 1.22593Z"
        fill="#4F4F4F"
        stroke="#4F4F4F"
        strokeWidth="0.32"
      />
    </svg>
  );
};

export default CrossIcon;
