const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_898_165)">
        <path
          d="M15.7828 13.8336L12.667 10.7183C12.5972 10.6486 12.5143 10.5934 12.4231 10.5559C12.3319 10.5183 12.2342 10.4991 12.1356 10.4994H11.6303C12.6481 9.19537 13.1301 7.55235 12.978 5.90531C12.8259 4.25827 12.0512 2.73124 10.8119 1.63559C9.57262 0.539937 7.96199 -0.0418262 6.30847 0.00892068C4.65494 0.0596676 3.08303 0.739103 1.91326 1.90869C0.743491 3.07828 0.0639485 4.64993 0.0131936 6.3032C-0.0375613 7.95647 0.544294 9.56684 1.64012 10.806C2.73594 12.0451 4.26321 12.8196 5.91051 12.9717C7.55782 13.1237 9.20109 12.6418 10.5053 11.6242V12.1294C10.505 12.228 10.5242 12.3257 10.5618 12.4169C10.5994 12.508 10.6546 12.5909 10.7242 12.6607L13.84 15.776C13.9094 15.8458 13.992 15.9012 14.0829 15.939C14.1738 15.9768 14.2713 15.9963 14.3697 15.9963C14.4682 15.9963 14.5656 15.9768 14.6565 15.939C14.7474 15.9012 14.83 15.8458 14.8994 15.776L15.7836 14.8919C15.9216 14.7503 15.9988 14.5603 15.9986 14.3626C15.9984 14.1648 15.921 13.975 15.7828 13.8336ZM6.50023 10.4994C5.70911 10.4994 4.93575 10.2648 4.27795 9.82534C3.62015 9.38588 3.10746 8.76127 2.80471 8.03048C2.50196 7.29969 2.42275 6.49555 2.57709 5.71975C2.73143 4.94395 3.11239 4.23133 3.6718 3.672C4.23121 3.11268 4.94395 2.73178 5.71987 2.57746C6.49579 2.42315 7.30006 2.50235 8.03096 2.80505C8.76187 3.10775 9.38658 3.62036 9.82611 4.27806C10.2656 4.93575 10.5002 5.70899 10.5002 6.49999C10.5005 7.02525 10.3971 7.54541 10.1962 8.03074C9.99526 8.51607 9.70063 8.95704 9.32915 9.32846C8.95767 9.69988 8.51663 9.99446 8.03123 10.1954C7.54582 10.3963 7.02558 10.4996 6.50023 10.4994Z"
          fill="#333333"
        />
      </g>
      <defs>
        <clipPath id="clip0_898_165">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SearchIcon;
