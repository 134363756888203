const TreeMapperIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4494_407)">
        <path
          d="M4.25317 11.5627H10.9985L12.488 15.5826H2.76367L4.25317 11.5627Z"
          fill="#F2F8F6"
          stroke="#007A49"
          strokeWidth="0.522734"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M11.7593 0.581736C11.9789 0.740058 11.9726 0.911951 11.9494 1.00032C11.8685 1.30746 11.4109 1.3659 11.3915 1.36824C11.2088 1.41995 10.2459 2.05849 9.85644 2.3735C9.94542 2.42929 10.0313 2.487 10.1134 2.54617C11.2444 3.36166 11.8126 4.56756 11.8505 6.23277C11.8979 8.31231 10.9131 9.31958 9.66639 10.5948L9.47695 10.7889C8.20388 12.0961 7.96045 13.2503 7.95814 13.2618L7.82812 13.9094L7.52858 13.3215C7.38716 13.0435 7.22998 12.718 7.06653 12.3796C6.70567 11.6324 6.29666 10.7855 5.98015 10.3018C5.94654 10.2504 5.89146 10.1754 5.82126 10.0793C5.20107 9.23101 3.55364 6.97757 4.22716 4.83747C4.63816 3.53151 5.84565 2.5187 7.81607 1.82716L8.27139 1.66736L8.10762 2.11839C8.07552 2.20682 8.04526 2.29652 8.01671 2.38755C8.11755 2.16891 8.23501 1.9951 8.37144 1.87962C9.19132 0.898278 10.0905 -0.0216468 11.2613 0.355682C11.4858 0.428039 11.6487 0.501981 11.7593 0.581736Z"
          fill="#007A49"
        />
        <path
          d="M7.73361 13.219C7.73361 13.219 7.96887 12.0113 9.31275 10.6313C10.6566 9.25137 11.6693 8.32226 11.6218 6.23882C11.58 4.40194 10.8888 3.20084 9.50836 2.43292C9.50773 2.43257 9.50714 2.43219 9.50659 2.4318C9.38706 2.34567 11.131 1.17078 11.3649 1.14266C11.5998 1.11441 12.1395 0.878297 11.1905 0.572436C10.2414 0.266574 9.47012 0.91946 8.53443 2.04137C7.32289 3.00458 7.73872 9.49374 7.73872 9.49374C7.73872 9.49374 6.80535 5.0359 7.8924 2.042C1.29827 4.35628 5.70619 9.46488 6.1728 10.1779C6.6394 10.891 7.27109 12.3097 7.73361 13.219ZM7.91797 14.5883L7.32431 13.4249C7.18225 13.1456 7.02435 12.8188 6.86018 12.4788C6.5023 11.7378 6.09668 10.8979 5.7882 10.4265C5.75603 10.3773 5.70318 10.3056 5.63599 10.2137C4.99734 9.34013 3.30086 7.01961 4.00871 4.77044C4.44249 3.39211 5.69805 2.32978 7.74051 1.61296L8.54487 1.33066C9.30554 0.48746 10.2039 -0.224327 11.3329 0.139535C11.5806 0.219363 11.7644 0.303848 11.8947 0.397817C12.2461 0.651164 12.1955 0.966935 12.1716 1.05768C12.0862 1.38228 11.7436 1.54428 11.4594 1.58885C11.2632 1.67014 10.6547 2.06589 10.2481 2.36181C11.4419 3.22141 12.0405 4.48623 12.0801 6.22747C12.1296 8.40245 11.1153 9.43998 9.83111 10.7536L9.64197 10.9473C8.42378 12.1982 8.18545 13.296 8.18318 13.3069L7.91797 14.5883Z"
          fill="#F2F8F6"
        />
      </g>
      <defs>
        <clipPath id="clip0_4494_407">
          <rect
            width="15.5946"
            height="15.8363"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TreeMapperIcon;
