import type { IconProps } from '../../../../../src/features/common/types/common';

export const ExploreIcon = ({ width = '19px' }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      viewBox="0 0 19 16"
      fill="none"
    >
      <g clipPath="url(#clip0_871_169)">
        <path
          d="M15.4375 0.571429C15.4375 0.255714 15.1718 0 14.8438 0H12.4688C12.1407 0 11.875 0.255714 11.875 0.571429V2.28571H15.4375V0.571429ZM2.37166 4.57107C2.27852 7.92286 0.128398 8.65071 0 13.2857V14.8571C0 15.4882 0.531777 16 1.1875 16H4.75C5.40572 16 5.9375 15.4882 5.9375 14.8571V9.14286H7.125V3.42857H3.55656C2.90232 3.42857 2.38947 3.94179 2.37166 4.57107ZM16.6283 4.57107C16.6105 3.94179 16.0977 3.42857 15.4434 3.42857H11.875V9.14286H13.0625V14.8571C13.0625 15.4882 13.5943 16 14.25 16H17.8125C18.4682 16 19 15.4882 19 14.8571V13.2857C18.8716 8.65071 16.7215 7.92286 16.6283 4.57107ZM6.53125 0H4.15625C3.8282 0 3.5625 0.255714 3.5625 0.571429V2.28571H7.125V0.571429C7.125 0.255714 6.8593 0 6.53125 0ZM8.3125 9.14286H10.6875V3.42857H8.3125V9.14286Z"
          fill="#333333"
        />
      </g>
      <defs>
        <clipPath id="clip0_871_169">
          <rect width="19" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
